/* eslint-disable @typescript-eslint/no-magic-numbers */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { now } from "@common/date";
import { Component, Prop, Vue } from "vue-property-decorator";
let default_1 = class default_1 extends Vue {
    get started() {
        return this.data.StartTime < now();
    }
    get end() {
        if (!this.data.EndTime || this.data.EndTime > now())
            return null;
        return this.data.EndTime;
    }
    get span() {
        var end = this.end || now();
        var diff = end.getTime() - this.data.StartTime.getTime();
        diff = Math.floor(diff / 60 / 1000);
        var result = "";
        if (diff > 1440)
            result += Math.floor(diff / 1440) + "天";
        diff %= 1440;
        if (diff > 60)
            result += Math.floor(diff / 60) + "時";
        result += diff % 60 + "分";
        return result;
    }
};
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
