/* eslint-disable @typescript-eslint/no-magic-numbers */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
let default_1 = class default_1 extends Vue {
    get play() {
        return this.data.Report.Total;
    }
    get price() {
        var _a, _b;
        return (_b = (_a = this.data.KioskItem) === null || _a === void 0 ? void 0 : _a.Item.PurchasePrice) !== null && _b !== void 0 ? _b : null;
    }
    get cost() {
        return this.price === null ? null : this.price * this.data.Report.Giftout;
    }
    get profit() {
        if (this.cost === null)
            return "未知";
        return (this.play * 10 - this.cost).toComma();
    }
    get averagePrice() {
        const giftout = this.data.Report.Giftout;
        if (this.price === null || giftout == 0)
            return null;
        return this.play * 10 / giftout;
    }
    get averageProfit() {
        if (this.averagePrice === null)
            return null;
        return this.averagePrice - this.price;
    }
    get profitRate() {
        if (this.averageProfit === null)
            return null;
        return 100 * this.averageProfit / (this.averageProfit + this.price);
    }
};
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
