var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { TEN_SECONDS } from "@common/communication";
import { LeapaItemTypeOptions } from "@/models/Item";
import { resetState as reportIndexReset } from "../report/Index.vue";
import { resetState } from "./Index.vue";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.LeapaItemTypeOptions = LeapaItemTypeOptions;
        this.hinted = false;
    }
    beforeMount() {
        this.load();
    }
    onStartTimeChange() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            if (this.data.StartTime < this.data.MerchantKiosk.StartTime) {
                this.data.StartTime = this.data.MerchantKiosk.StartTime;
                if (!this.hinted) {
                    this.hinted = true; // 訊息提示一次就夠了
                    yield this.alert("首次編輯時間不可早於設備綁定時間");
                }
            }
        });
    }
    get title() {
        return this.id ? "編輯貨品" : "設備換貨";
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            try {
                this.data = yield post("/api/Item/GetEditModel", { Id: this.id, MerchantKioskId: this.mkId });
                if (this.data.Price == 0)
                    this.data.Price = null;
            }
            catch (e) {
                this.data = null;
                throw e;
            }
        });
    }
    send() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            this.data.StartTime.setSeconds(0, 0);
            while (this.data.StartTime < this.data.MerchantKiosk.StartTime) {
                this.data.StartTime.setMinutes(this.data.StartTime.getMinutes() + 1);
            }
            var data = {
                MerchantKioskId: this.data.MerchantKiosk.Id,
                Id: this.data.Id,
                LeapaItemType: this.data.LeapaItemType,
                StartTime: this.data.StartTime.toIsoTime(),
                Name: this.data.Name,
                Price: this.data.Price,
                Photo: this.data.Photo,
            };
            yield post("/api/Item/Edit", data, TEN_SECONDS);
            resetState();
            reportIndexReset();
            this.$router.back();
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "mkId", void 0);
__decorate([
    Prop()
], default_1.prototype, "id", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
