
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { now } from "@common/date";
	import { cache } from "@/views/report/Detail.vue";
	import { leapaItemTypeString } from "@/models/Item";
	import Formula from "./Formula.vue";
	import TimeSpan from "./TimeSpan.vue";

	import type { KioskItemIndexModel } from "@/models/Item";

	@Component({ components: { Formula, TimeSpan } })
	export default class extends Vue {
		@Prop() public data!: KioskItemIndexModel;

		protected readonly leapaItemTypeString = leapaItemTypeString;

		protected edit(): void {
			this.$router.push("/item/edit/" + this.data.MerchantKiosk.Id + (this.data.KioskItem ? "/" + this.data.KioskItem.Id : ""));
		}

		protected report(): void {
			cache.query = {
				start: this.data.StartTime,
				end: this.data.EndTime || now(),
				init: 4,
			};
			this.$router.push('/report/detail/' + this.data.MerchantKiosk.Id);
		}
	}
