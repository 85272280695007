var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { now } from "@common/date";
import { cache } from "@/views/report/Detail.vue";
import { leapaItemTypeString } from "@/models/Item";
import Formula from "./Formula.vue";
import TimeSpan from "./TimeSpan.vue";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.leapaItemTypeString = leapaItemTypeString;
    }
    edit() {
        this.$router.push("/item/edit/" + this.data.MerchantKiosk.Id + (this.data.KioskItem ? "/" + this.data.KioskItem.Id : ""));
    }
    report() {
        cache.query = {
            start: this.data.StartTime,
            end: this.data.EndTime || now(),
            init: 4,
        };
        this.$router.push('/report/detail/' + this.data.MerchantKiosk.Id);
    }
};
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
default_1 = __decorate([
    Component({ components: { Formula, TimeSpan } })
], default_1);
export default default_1;
