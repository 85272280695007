var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Vue } from "vue-property-decorator";
import { now } from "@common/date";
import FilterModal from "@/components/modal/FilterModal.vue";
import { setScroll } from "@/views/setScroll";
import { toIndexQuery, newQuery } from "@/models/Report";
import Base from "../Base";
import Kiosk from "./components/Kiosk.vue";
let cache = null;
export function resetState() {
    if (cache)
        cache.state = undefined;
}
export function resetCache() {
    cache = null;
}
const TIMEOUT = 120000;
let default_1 = class extends Base {
    constructor() {
        super(...arguments);
        this.data = null;
        this.tags = [];
        this.query = newQuery();
    }
    beforeMount() {
        post('/api/tag').then(v => this.tags = v);
        let scroll = 0;
        if (cache) {
            this.query = cache.query;
            if (cache.state) {
                scroll = cache.state.scroll;
                this.data = cache.state.data;
                this.time = cache.state.time;
                Vue.nextTick(() => setScroll(scroll));
            }
            else {
                this.load();
            }
            resetCache();
        }
        else {
            this.load();
        }
    }
    beforeRouteLeave(to, from, next) {
        if (to.path.startsWith('/item') || to.path.startsWith('/report/detail/')) {
            cache = {
                query: this.query,
                state: {
                    time: this.time,
                    data: this.data,
                    scroll: document.querySelector('main').scrollTop,
                },
            };
        }
        else {
            resetCache();
        }
        next();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            try {
                const query = toIndexQuery(this.query);
                delete query.Start; // 只檢視當前商品
                this.data = yield post("/api/Item", query, TIMEOUT);
                this.update = now();
            }
            catch (e) {
                this.data = null;
                throw e;
            }
        });
    }
    showFilter() {
        return __awaiter(this, void 0, void 0, function* () {
            if (yield this.$refs.filter.show())
                yield this.load();
        });
    }
};
default_1 = __decorate([
    Component({ components: { Kiosk, FilterModal } })
], default_1);
export default default_1;
